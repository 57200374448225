<template>
  <dir id="print">
    <p style="text-align: center; font-weight: 600">开票通知单</p>
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8" style="display: flex;">
          <div>发票抬头：</div>
          <div style="flex: 1;">{{ data.customer }}</div>
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          业务编号：
          <span v-for="(item, index) in data.order_num_list" :key="index">{{ item }}</span>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          申请日期：{{ data.apply_time }}
        </a-col>
      </a-row>
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>费用名称</th>
            <th>金额(不含税)</th>
            <th>税率</th>
            <th>价税合计</th>
            <th>增/普票</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td>{{ item.fee_name }}</td>
            <td>{{ item.price_total }}</td>
            <td>{{ item.tax_rate }}</td>
            <td>{{ item.tax_price_total }}</td>
            <td v-if="data.invoice_type === 0">普通发票</td>
            <td v-else-if="data.invoice_type === 1">专用发票</td>
            <td v-else-if="data.invoice_type === 2">缴款单</td>
            <td v-else>-</td>
          </tr>
          <tr class="h100">
            <td style="font-weight: bold;">备注</td>
            <td colspan="5" style="font-weight: bold;">{{ data.apply_remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer">
      <span>开票人：{{ userInfo.name }}</span>
      <span>申请人：{{ data.applicant_name }}</span>
      <span>部门经理：张慧</span>
      <span>申请时间：{{ date }}</span>
    </p>
  </dir>
</template>

<script>
import moment from 'moment'

export default {
  name: 'YmsBalanceNoticeBill',
  props: {
    dataSource: {
      type: Array,
      default: null
    },
    dataRecord: {
      type: Object,
      default: null
    }
  },
  watch: {
    dataSource: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    },
    dataRecord(val, oldVal) {
      this.data = val
    }
  },
  data() {
    return {
      data: {},
      list: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    moment,
    initData() {
      this.data = this.dataRecord
      this.list = this.dataSource
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 0px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.title{
  width: 100%;
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
